// Generated by purs version 0.13.6
"use strict";
var $foreign = require("./foreign.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var logo = function (className) {
    return React_Basic_DOM_Generated.img()({
        className: className,
        src: $foreign.imgSrc
    });
};
module.exports = {
    logo: logo,
    imgSrc: $foreign.imgSrc
};
