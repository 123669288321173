"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var React_Basic = require("../React.Basic/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var SiloDashboard_Client_Screens_Login = require("../SiloDashboard.Client.Screens.Login/index.js");
var Type_Equality = require("../Type.Equality/index.js");

// App Component
var mkAppComponent = function __do() {
    var loginScreen = React_Basic_Hooks.memo(SiloDashboard_Client_Screens_Login.mkLoginScreen)();
    return React_Basic_Hooks.component()()()("App")(function (v) {
        return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div_([ React_Basic.element(loginScreen)({}) ]));
    })();
};
module.exports = {
    mkAppComponent: mkAppComponent
};
