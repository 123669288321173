// Generated by purs version 0.13.6
"use strict";
var AdminUi_Antd_Button = require("../AdminUi.Antd.Button/index.js");
var AdminUi_Antd_Form = require("../AdminUi.Antd.Form/index.js");
var AdminUi_Antd_Input = require("../AdminUi.Antd.Input/index.js");
var AdminUi_Antd_PropLits = require("../AdminUi.Antd.PropLits/index.js");
var AdminUi_Antd_Typography = require("../AdminUi.Antd.Typography/index.js");
var AdminUi_Colors = require("../AdminUi.Colors/index.js");
var AdminUi_Logo = require("../AdminUi.Logo/index.js");
var AdminUi_Spacer = require("../AdminUi.Spacer/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Effect = require("../Effect/index.js");
var Jss_ReactJss = require("../Jss.ReactJss/index.js");
var React_Basic_DOM = require("../React.Basic.DOM/index.js");
var React_Basic_DOM_Generated = require("../React.Basic.DOM.Generated/index.js");
var React_Basic_Events = require("../React.Basic.Events/index.js");
var React_Basic_Hooks = require("../React.Basic.Hooks/index.js");
var React_Basic_Hooks_Internal = require("../React.Basic.Hooks.Internal/index.js");
var Type_Equality = require("../Type.Equality/index.js");
var mkLoginScreen = (function () {
    var useStyles = Jss_ReactJss.createUseStyles()()()({
        submitButton: Jss_ReactJss.style({
            width: "100%"
        }),
        linkBtnContainer: Jss_ReactJss.style({
            display: "flex",
            width: "100%",
            justifyContent: "center"
        }),
        logo: Jss_ReactJss.style({
            width: 95
        }),
        logoContainer: Jss_ReactJss.style({
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }),
        subTitle: Jss_ReactJss.style({
            textAlign: "center"
        }),
        form: Jss_ReactJss.style({
            backgroundColor: "white",
            maxWidth: "420px",
            minHeight: "100vh",
            padding: "50px 45px",
            borderRadius: "0px",
            flex: 1,
            display: "flex",
            flexDirection: "column"
        }),
        container: Jss_ReactJss.style({
            backgroundColor: AdminUi_Colors.primary,
            width: "100vw",
            minHeight: "100vh",
            display: "flex",
            alignItems: "flex-start"
        })
    })([ {
        minWidth: 420,
        classes: {
            container: Jss_ReactJss.style({
                alignItems: "center",
                justifyContent: "center"
            }),
            form: Jss_ReactJss.style({
                borderRadius: "20px",
                minHeight: "initial"
            })
        }
    } ]);
    var linkBtn = function (v) {
        return React_Basic_DOM_Generated.div()({
            className: v.className,
            children: Control_Applicative.pure(Control_Applicative.applicativeArray)(AdminUi_Antd_Button.button()({
                type: AdminUi_Antd_PropLits.link,
                size: AdminUi_Antd_PropLits.small,
                onClick: React_Basic_Events.handler_(v.onClick),
                children: [ React_Basic_DOM.text(v.text) ]
            }))
        });
    };
    return React_Basic_Hooks.component()()()("LoginScreen")(function (v) {
        return React_Basic_Hooks_Internal.bind(React_Basic_Hooks_Internal.ixBindRender)(useStyles({}))(function (v1) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(React_Basic_DOM_Generated.div()({
                className: v1.container,
                children: [ AdminUi_Antd_Form.form()({
                    colon: false,
                    layout: AdminUi_Antd_PropLits.vertical,
                    className: v1.form,
                    children: [ React_Basic_DOM_Generated.div()({
                        className: v1.logoContainer,
                        children: [ AdminUi_Logo.logo(v1.logo) ]
                    }), AdminUi_Spacer.vspacer(7), AdminUi_Antd_Typography.txt()({
                        className: v1.subTitle,
                        children: "Sign in to access your dashboard"
                    }), AdminUi_Spacer.vspacer(7), AdminUi_Antd_Form.formItem()({
                        label: "Silo Code",
                        children: AdminUi_Antd_Input.input()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Email",
                        children: AdminUi_Antd_Input.input()({})
                    }), AdminUi_Antd_Form.formItem()({
                        label: "Password",
                        children: AdminUi_Antd_Input.inputPassword()({})
                    }), AdminUi_Spacer.vspacer(5), AdminUi_Antd_Button.button()({
                        type: AdminUi_Antd_PropLits.primary,
                        htmlType: "submit",
                        className: v1.submitButton,
                        loading: false,
                        children: [ React_Basic_DOM.text("Log In") ]
                    }), AdminUi_Spacer.vspacer(8), linkBtn({
                        text: "Forgot Password?",
                        onClick: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                        className: v1.linkBtnContainer
                    }), AdminUi_Spacer.vspacer(2), linkBtn({
                        text: "Forgot Silo Code?",
                        onClick: Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit),
                        className: v1.linkBtnContainer
                    }) ]
                }) ]
            }));
        });
    });
})();
module.exports = {
    mkLoginScreen: mkLoginScreen
};
